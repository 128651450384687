import clock from './clock.md';
import snips from './snips.md';
import outlet from './outlet.md';
import logger from './logger.md';
import switcher from './switcher.md';
import wg from './wg.md';
// import bib from './bib.md';
import hmnn from './hmnn.md';
// import airRohr from './airRohr.md';
import sensor from './sensor.md';

export { default as intro } from './intro.md';

export const projects = [
	// {
	// 	text: airRohr,
	// 	id: 'airRohr',
	// 	date: '04/03/2025',
	// },
	{
		text: sensor,
		date: '30/11/2024',
		id: 'sensor',
	},
	{
		text: hmnn,
		date: '01/02/2022',
		id: 'hmnn',
	},
	// {
	//   text: bib,
	//   id: 'UBM',
	// },
	{
		text: clock,
		id: 'clock',
		date: '01/07/2020',
	},
	{
		text: snips,
		id: 'snips',
		date: '30/09/2019',
	},
	{
		text: outlet,
		id: 'outlet',
	},
	{
		text: logger,
		id: 'logger',
	},
	{
		text: switcher,
		id: 'switcher',
	},
	{
		text: wg,
		id: 'wg',
	},
];
