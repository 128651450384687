import { useEffect, useRef } from 'react';

export function useThrottledCallback(
	callback,
	wait,
) {
	const timeout = useRef();

	function cleanup() {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
	}

	useEffect(() => () => cleanup, []);

	return function throttledCallback(...args) {
		if (!timeout.current) {
			callback(...args);

			timeout.current = setTimeout(() => {
				timeout.current = null;
			}, wait);
		}
	};
}
