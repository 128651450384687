import { useEffect } from 'react';
import { Article, Card } from '../components';
import { intro } from '../../content';

function Home() {
	useEffect(() => {
		document.title = 'Home | Bruno Péjac';
	});

	return (
		<>
			<Card className="bg-slate-100 dark:bg-slate-900 text-slate-900 dark:text-slate-100 flex flex-col-reverse md:flex-row items-start md:items-center mb-24 mt-24 w-64 md:w-auto md:h-64">
				<div className="flex flex-col justify-center flex-1 min-w-0 md:h-full mx-6 md:mt-8 mb-4 md:ml-8 md:mr-4">
					<div className="md:w-full flex flex-col justify-between items-stretch space-y-1 divide-y divide-slate-200 dark:divide-slate-800">
						<h1 className="text-2xl font-bold pl-1">Bruno Péjac</h1>
						<span className="text-sm pl-1 pt-1">
							software engineer
						</span>
					</div>
					<ol className="flex flex-col space-y-2 my-2 mt-4 pl-1 text-sm">
						<li>
							<a className="flex items-center hover:text-sky-800 dark:hover:text-sky-300" href="https://github.com/brunottonurb" title="Github" rel="noopener noreferrer" target="_blank">
								<svg
									className="h-5 w-5 mr-2"
									fill="currentColor"
									role="img"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
								</svg>
								GitHub
							</a>
						</li>
						<li>
							<a className="flex items-center hover:text-sky-800 dark:hover:text-sky-300" href="https://t.me/boldbruno" title="Telegram" rel="noopener noreferrer" target="_blank">
								<svg
									className="h-5 w-5 mr-2"
									fill="currentColor"
									style={{
										clipRule: 'evenodd',
										fillRule: 'evenodd',
										strokeLinejoin: 'round',
										strokeMiterlimit: '1.41421',
									}}
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z" />
								</svg>
								Telegram
							</a>
						</li>
						<li>
							<a className="flex items-center hover:text-sky-800 dark:hover:text-sky-300" href="m&#97;ilto&#58;&#37;6&#50;run&#111;&#64;%70e&#37;6A%6&#49;&#99;&#46;%65u" title="Email" rel="noopener noreferrer" target="_blank">
								<svg
									className="h-5 w-5 mr-2"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
								</svg>
								{(() => { const s = 'Nbjm'; let m = ''; for (let i = 0; i < s.length; i++)m += String.fromCharCode(s.charCodeAt(i) - 1); return m; })()}
							</a>
						</li>
						<li>
							<a className="flex items-center hover:text-sky-800 dark:hover:text-sky-300" href="/pgp_keys.asc" title="PGP public key">
								<svg
									className="h-5 w-5 mr-2"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
								</svg>
								PGP key
							</a>
						</li>
					</ol>
				</div>
				<div className="w-full md:w-auto md:h-full overflow-hidden relative text-slate-100 dark:text-slate-900">
					<img
						className="w-full h-auto md:h-full md:w-auto grayscale md:ml-1 md:mr-1 -mt-5 md:mt-0"
						src="small.jpg"
						alt="portrait"
						width="800"
						height="800"
					/>
					<svg
						fill="currentColor"
						viewBox="0 0 100 100"
						className="absolute inset-x-0 bottom-0 md:inset-y-0 md:left-0 z-10 rotate-90 md:rotate-0"
						preserveAspectRatio="none"
					>
						<polygon className="invisible md:visible" points="0,0 15,0 5,100 0,100" />
						<polygon points="100,0 95,0 85,100 100,100" />
					</svg>
				</div>
			</Card>
			<Article text={intro} />
			<Article>
				<a className="flex items-center hover:text-sky-800 dark:hover:text-sky-300" href="ba.pdf" title="Bachelor Thesis">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
						/>
					</svg>
					<div>
						<div className="text-slate-500">Bachelor Thesis</div>
						<div>Evaluating the Impact of Rate Limiting Mechanisms on Adaptive Video Streaming</div>
					</div>
				</a>
			</Article>
		</>
	);
}

export default Home;
